import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Bounce from 'react-reveal/Bounce'

import Layout from '../components/Layout'
import Button from '../components/Button'

const Wrapper = styled.div`
  @import url('https://fonts.googleapis.com/css?family=Kaushan+Script');
  background-color: #122A59;
	display: flex;
	align-items: center;
	flex-direction: column;
  text-align: center;
  padding: 1rem;
  animation: hide;
  width: 100%;
`

const Header = styled.h1`
  margin-top: 0;
  background-color:#ffff;
  padding: 1rem;
  width: 100%;
`

const Subheader = styled.h2`
  color: #ffff;
  margin: 0;
  font-size: 2rem;
  border-radius: 10px;
  border: 10px solid #DFC75E;
  margin-top: 1rem;
  padding: 1rem;
  width: 100%;
  margin: 2rem auto;
  font-family: 'Times New Roman', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 15rem;
`

const Text = styled.p`
  color: #ffff;
  font-size: ${props => props.big ? 2 : 2.3}rem;
  font-family: 'Kaushan Script', serif;
  margin-bottom: 0;
`

const Logo2 = styled(Img).attrs({
  alt: 'logo'
})`
  width: 100%;
  max-height: 50%;
`

const Number = styled.a.attrs({
  target: '_blank',
  href: 'tel:956-968-9556'
})`
  text-decoration: none;
  color: white;
`

const Motto = ({data: {markdownRemark: {frontmatter}}}) =>
  <Layout frontmatter={{...frontmatter, backgroundColor: '#122A59'}}>
    <Wrapper>
      <div>
        <Header>
          <Logo2 sizes={frontmatter.logo.childImageSharp.sizes} />
        </Header>
        <Bounce ssrReveal>
          <Subheader>{frontmatter.text2}
            <Text>{frontmatter.text3}</Text>
            <br />
            <Number>(956) 968-9556</Number>
          </Subheader>
        </Bounce>
        <section className='w-100 flex-column flex justify-center items-center'>
          <Button href={frontmatter.link}>{frontmatter.buttonText}</Button>
        </section>
      </div>
    </Wrapper>
  </Layout>

export default Motto

export const pageQuery = graphql`
  query MottoBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        templateKey
        title
        description
        text
        text2
        text3
        buttonText
        link
        logo {
          childImageSharp {
            sizes {
              ...GatsbyImageSharpSizes_noBase64
            }
          }
        }
        favicon {
          publicURL
        }
      }
    }
  }
`