const colors = {
  primary: '#e86161',
  accent1: '#e86161',
  accent2: '#f9bc99',
  accent3: '#eede91',
  accent4: '#97dbe6',
  accent5: '#155d89',
  black: '#141414',
  white: '#FFFFFF',
  gray: '#545555',
  background: '#FFFFFF',
  backgroundDark: '#f6f9fc',
  text: '#2a2a2a',
  facebook: '#3b5998'
}

export default colors