import styled from 'styled-components'

const Button = styled.a.attrs({
  className: 'w-100 tc',
  target: '_blank'
})`
  font-weight: bold;
  text-decoration: none;
  color: ${props => props.color || '#472828'};
  background-color: ${props => props.backgroundColor || '#DFC75E'};
  padding: 1rem;
  border-radius: 4px;
  border: none;
`

export default Button