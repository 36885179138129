import React from 'react'
import Helmet from 'react-helmet'
import get from 'lodash/get'

const SEO = ({frontmatter: {title, description, logo, favicon}}) =>
  <Helmet>

    <title>{title}</title>
    <link rel='shortcut icon' href={get(favicon, 'publicURL', 'favicon.ico')} />

    <meta property='description' content={description} />

    <meta property='og:title' content={title} />
    <meta property='og:type' content='website' />
    <meta property='og:description' content={description} />
    <meta property='og:image' content={get(logo, 'publicURL')} />

    <meta name='twitter:title' content={title} />
    <meta name='twitter:description' content={description} />
    <meta name='twitter:image:src' content={get(logo, 'publicURL')} />

    <meta itemProp='name' content={title} />
    <meta itemProp='description' content={description} />
    <meta itemProp='image' content={get(logo, 'publicURL')} />

  </Helmet>

export default SEO
